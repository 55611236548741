import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Company from "../../assets/images/Film-Chronicle-Logo-Tertiary-Dark.svg"
import Sponsors from "../../assets/images/gray-hands.svg"
import Judges from "../../assets/images/Peace-Light.svg"
import Rules from "../../assets/images/Medal-Light.svg"
import Seo from "../../components/Seo"
import LoadMore from "../../assets/images/Load-more-arrow.svg"
import { Helmet } from "react-helmet"

const aboutCompany = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>About Film Chronicle</title>
        <link rel="canonical" href="https://filmchronicle.com/about/company" />
      </Helmet>
      <Seo title="Company" description="Together we're empowering the next generation of filmmakers to share their stories." image="../../../assets/images/About-Us__Company-Hero.png" />
      <header className="hero">
        <StaticImage
          src="../../assets/images/About-Us__Company-Hero.png"
          alt="three filmmakers on a hill"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <h1 style={{marginBottom: "1.5rem"}}>About Film Chronicle</h1>
            <p style={{color: "#fff", fontFamily: "Proxima Nova, sans-serif", fontWeight: "500", textTransform: "none", maxWidth: "40rem"}}>Together we’re empowering the next generation of filmmakers to share their stories.</p>
          </div>
        </div>
      </header>
      <div className="container-md">
        <div className="grid-cols-4 center">
        <Link to="/about/company">
            <img
              src={Company}
              alt="Film Chronicle logo"
              className="hero-img"
              placeholder="tracedSVG"
              style={{margin: "auto", paddingBottom: "15px"}}
            />
            <h3 style={{textAlign: "center"}}>COMPANY</h3>
            <div style={{paddingTop: "15px",borderBottom: "0.25rem solid red"}}>
              </div>
          </Link>
        <Link to="/about/judges">
          <img
            src={Judges}
            alt="peace sign"
            className="hero-img"
            placeholder="tracedSVG"
            style={{fontSize: "100px", margin: "auto", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>JUDGES</h3>
        </Link>
        <Link to="/about/rules">
          <img
            src={Rules}
            alt="award"
            className="hero-img"
            placeholder="tracedSVG"
            style={{fontSize: "100px", margin: "auto", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>RULES &amp; FAQs</h3>
        </Link>
        <Link to="/about/sponsors">
          <img
            src={Sponsors}
            alt="sponsors icon"
            className="hero-img"
            style={{fontSize: "100px", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>SPONSORS</h3>
        </Link>
        </div>
      </div>
      <div className="container">
            <div className="grid-cols-4 grid-cols-1">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Our Vision</p>
              </div>
              <div className="col-span-3">
                <p>At Film Chronicle, we believe the student community needs to come together. Our current system has sheltered us from the indie and rising film stars. As a result, there are too many student films gone unnoticed. The goal is to break the cycle and give students fame earlier on in their careers.</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Monthly Festival</p>
              </div>
              <div className="col-span-3">
                <p>By offering a 100% free service for students at any school to participate and know their films will circulate among other students–we’re able to commune together without having to jump through corporate hoops and have our works of art reach a wider audience.</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Film Library</p>
              </div>
              <div className="col-span-3">
                <p>A meeting point for creatives to cultivate and promote current, past, and future film students in the media industry. The platform is designed for the students by students, where aspiring filmmakers can find inspiration, impart knowledge and experience, connect, and share unbiased critiques.
</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Our Team</p>
              </div>
              <div className="col-span-3">
                <div className="company-cols-2 grid-cols-1">
                  <div>
                    <img alt="Shunsuke Funakoshi" style={{marginBottom: "1rem"}} className="company-pics" src="https://www.datocms-assets.com/48645/1634023022-ellipse-14.png?h=200"></img>
                    <p style={{textAlign: "center", textTransform: "uppercase"}}>Shunsuke Funakoshi</p>
                    <p style={{textAlign: "center", fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666"}}>Founder</p>
                  </div>
                  <div>
                    <img alt="Alkis Tzortzoglou" style={{marginBottom: "1rem"}} className="company-pics" src="https://www.datocms-assets.com/48645/1649446592-alkis-new.png?h=200"></img>
                    <p style={{textAlign: "center", textTransform: "uppercase"}}>Alkis Tzortzoglou</p>
                    <p style={{textAlign: "center", fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666"}}>Co-founder</p>
                  </div>
                  <div>
                    <img alt="Trey Burghardt" style={{marginBottom: "1rem"}} className="company-pics" src="https://www.datocms-assets.com/48645/1634023037-trey.png?h=200"></img>
                    <p style={{textAlign: "center", textTransform: "uppercase"}}>Trey Burghardt</p>
                    <p style={{textAlign: "center", fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666"}}>Head of Design</p>
                  </div>
                  <div>
                    <img alt="Lukas Mason" style={{marginBottom: "1rem"}} className="company-pics" src="https://www.datocms-assets.com/48645/1639867557-luke-headshot.png?h=200"></img>
                    <p style={{textAlign: "center", textTransform: "uppercase"}}>Lukas Mason</p>
                    <p style={{textAlign: "center", fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666"}}>Lead Developer</p>
                  </div>
                  <div>
                    <img alt="Fernanda Parrado" style={{marginBottom: "1rem"}} className="company-pics" src="https://www.datocms-assets.com/48645/1634023027-fernanda.png?h=200"></img>
                    <p style={{textAlign: "center", textTransform: "uppercase"}}>Fernanda Parrado</p>
                    <p style={{textAlign: "center", fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666"}}>Library Director</p>
                  </div>
                  <div>
                    <img alt="Vijay Singh"style={{marginBottom: "1rem"}} className="company-pics" src="https://www.datocms-assets.com/48645/1634023042-vijay.png?h=200"></img>
                    <p style={{textAlign: "center", textTransform: "uppercase"}}>Vijay Singh</p>
                    <p style={{textAlign: "center", fontFamily: "Proxima Nova, sans-serif", fontWeight: "400", color: "#666666"}}>Festivals Director</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
        <div className="grid-cols-3">
          <p className="col-span-3" style={{textAlign: "center", marginBottom: "4rem", display: "none"}}>No more results</p>
            <a style={{gridColumnStart: "6"}} href="#top">
              <img
                src={LoadMore}
                alt="flour and eggs"
                placeholder="tracedSVG"
                layout="constrained"
              />
            </a>
        </div>
      </div>
    </Layout>
  )
}

export default aboutCompany
